<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title ?? "Obrinha";
      }
    }
  }
};
</script>

<style>
/*CSS de TERCEIROS que modificamos */
.p-message.p-message-error,
.p-message.p-message-warn,
.p-message.p-message-success {
  position: fixed !important;
  top: 12px !important;
  right: 15px !important;
  z-index: 999 !important;
}

button.p-sidebar-close.p-link {
  margin-right: 8px;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f8f8f8;
  overflow: hidden;
}

.p-ripple.purple .p-ink {
  background: rgba(256, 39, 176, 0.3);
}

a {
  text-decoration: none;
}

.p-sidebar {
  padding: 0 !important;
}

.p-sidebar-close-icon {
  color: white;
  font-size: 20px;
}

.p-sidebar-close.p-link {
  margin-top: 15px;
}

@font-face {
  font-family: "pvFont";
  src: url(./assets/font/Montserrat-Regular.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "pvFontBold";
  src: url(./assets/font/Montserrat-Bold.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 1s ease;
}

.fade-enter-from {
  opacity: 0;
}

@media screen and (max-width: 567px) {
  body {
    overflow: auto;
  }
}
</style>
