<template>
  <div style="overflow: hidden; font-family: pvFont">
    <transition name="slide-fade" appear>
      <div class="p-grid">
        <div class="p-md-4 p-sm-12 pvDivLateral">
          <div class="pvCarrinhoLateral">
            <div class="p-text-center">
              <router-link to="/" v-slot="{ navigate }">
                <img src="../assets/logo.png" style="max-width: 100%; margin: auto" @click="navigate" />
              </router-link>
              <p style="
                  color: rgb(187 187 187);
                  font-style: italic;
                  font-size: 14px;
                ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent convallis ligula vitae augue aliquam, in porta purus
                commodo.
              </p>
            </div>
          </div>
        </div>

        <div class="p-md-8 p-sm-12">
          <form class="pvMainForm" @submit="sendForm">
            <Message v-for="err in erros" :severity="err.severity" :key="err.descricao" :life="4000" :sticky="false">{{
                err.descricao
            }}</Message>

            <div style="display: flex; flex-direction: column">
              <h3>Selecione o estado em que a obra irá ocorrer:</h3>
              <filtered-select :options="uf" @selected="saveChoice($event)">
              </filtered-select>

              <h3 style="margin-top: 40px">Qual seu e-mail?</h3>

              <input class="input-field" id="email-input" placeholder="Digite seu email..." required v-model="email"
                type="email" />

              <div style="display: flex; justify-content: flex-end">
                <button-prime type="submit" class="pvBtnPrime" v-show="btnSimular" label="SIMULAR"
                  icon="pi pi-arrow-right" iconPos="right" />
              </div>
            </div>

            <div style="
                border-top: solid 1px #ccc;
                margin-top: 30px;
                color: rgb(150, 149, 149);
                font-size: 14px;
              ">
              <p>
                Desenvolvido por
                <a style="color: #393939" href="#">Ponto&Vírgula</a> 2020.
              </p>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FilteredSelect from "./utils/FilteredSelect.vue";

export default {
  name: "HomePage",
  components: { FilteredSelect },
  data() {
    return {
      uf: [],
      email: "",
      btnSimular: false,
      erros: [],
    };
  },
  methods: {
    sendForm: function (e) {
      e.preventDefault();

      sessionStorage.setItem("email", this.email);

      const params = new URLSearchParams();
      params.append("email", this.email);
      params.append("id_estado", this.uf.escolha.id);

      this.axios.post("/back-obrinha/orcamento_usuario.php", params).then((response) => {
        sessionStorage.setItem("id_orcamento", response.data.idOrcamento);
        this.$router.push("/simular");
      })

    },
    saveChoice: function (event) {
      let title = document.getElementsByTagName("h3")[1];
      title.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      this.uf.escolha = event;
      localStorage.setItem("uf", event.id);
      this.activateBtnSimular();
    },
    activateBtnSimular: function () {
      this.btnSimular = true;
    },
    deactivateBtnSimular: function () {
      this.btnSimular = false;
    },
    tirarFoco: function () {
      document.getElementsByClassName("p-dropdown-filter")[0].blur();
    },
    pvGetUf: function () {
      this.axios
        .get("/back-obrinha/uf.php")
        .then((response) => {
          this.uf = response.data;
        })
        .catch(() => {
          window.setTimeout(this.pvGetUf(), 2000);
        });
    },
  },
  created() {
    this.pvGetUf();

    this.email = sessionStorage.getItem("email");

    sessionStorage.removeItem("session-cart");
  },
};
</script>

<style scoped>
.pvMainForm {
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);

  padding: 20px;
  height: 100vh;

  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pvMainForm .input-field {
  margin-bottom: 20px;
  border-radius: 3px;
  border: 1px solid #ced4da;
  padding: 5px;
  font-size: 14px;
  font-family: pvFont;
}

.input-field::placeholder {
  color: #495057;
}

.pvBtnPrime {
  background-color: #277ba3;
}

.pvBtnPrime:hover {
  background-color: #2b8bb8;
}

.pvBtnCalculadora {
  font-family: pvFont;

  padding: 10px;
  margin-bottom: 10px;

  border-radius: 4px;
  border: solid 1px #277ba3;

  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;

  box-shadow: 0 8px 18px -8px #226c8f;

  transition: all 0.4s ease-out;
}

.pvBtnCalculadora:hover {
  transform: translateY(-4px);
  transition: all 0.4s ease-out;
  background-color: rgba(39, 123, 163, 0.3);
}

.pvBtnCalculadora.active {
  transform: translateY(8px);
  transform: scale(0.95);
  box-shadow: none;
  background-color: #277ba3;
  transition: all 0.4s ease-out;
  color: white;
}

.pvBtnIcon {
  height: 50px;
  display: block;
  align-self: center;
}

.pvCarrinhoLateral {
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .pvDivLateral .pvCarrinhoLateral {
    height: auto;
  }

  .pvMainForm {
    height: auto;
  }
}
</style>
