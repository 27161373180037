<template>
  <div style="overflow: hidden; font-family: pvFont">
    <transition name="slide-fade" appear>
      <div class="p-grid">
        <div class="p-md-4 p-sm-12 pvDivLateral">
          <div class="pvCarrinhoLateral">
            <div class="p-text-center">
              <router-link to="/" v-slot="{ navigate }">
                <img src="../assets/logo.png" style="max-width: 100%; margin: auto" @click="navigate" />
              </router-link>
              <p style="
                  color: rgb(187 187 187);
                  font-style: italic;
                  font-size: 14px;
                ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent convallis ligula vitae augue aliquam, in porta purus
                commodo.
              </p>
            </div>
            <div class="pvCarrinho">
              <h3 style="margin-top: 40px">SEU CARRINHO:</h3>
              <div class="sideBarItemsWrapper">
                <div v-for="(f, itemIndex) in form" :key="f">
                  <div class="pvSidebarItem" v-if="form[0].comodo.escolha != 0 || form.length == 1">
                    <div style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    ">
                      <label v-if="f.comodo.escolha != 0" :style="f.item == opcao.item ? 'color: #277ba3' : ''">
                        <i class="pi pi-angle-right" style="font-size: 20px" v-show="f.item == opcao.item"></i>
                        {{ f.comodo.escolha.descricao }} <br />
                        <small>{{ pvFormatToMoney(f.comodo.orcamento) }}</small>
                      </label>
                      <label v-else :style="f.item == opcao.item ? 'color: #277ba3' : ''">
                        <i class="pi pi-angle-right" style="font-size: 20px" v-show="f.item == opcao.item"></i>
                        Cômodo...?
                      </label>
                      <div>
                        <button-prime icon="pi pi-pencil" class="pvBtnPrime p-button-outlined p-button-warning"
                          style="margin-right: 10px" @click="pvClickedOnItem(itemIndex)" />
                        <button-prime icon="pi pi-trash" class="pvBtnPrime p-button-outlined p-button-danger"
                          @click="pvDeleteItem(itemIndex)" />
                      </div>
                    </div>
                  </div>
                  <p v-else>Ops... Seu carrinho ainda está vazio ;-;</p>
                </div>
              </div>
              <div class="footerCarrinho">
                Total:
                <strong class="pvValor">{{
                  pvFormatToMoney(orcamentoTotal)
                }}</strong>
              </div>
            </div>
          </div>
        </div>

        <!--pvMainForm é a parte branca -->
        <div class="p-col p-md-8 p-sm-12">
          <transition name="slide-fade" appear>
            <div class="pvMainForm">
              <div>
                <Message v-for="err in erros" :severity="err.severity" :key="err.descricao" :life="4000"
                  :sticky="false">{{ err.descricao }}</Message>
                <div v-for="(f, itemIndex) in form" :key="f" v-show="opcao.item == f.item">
                  <div style="
                      display: flex;
                      justify-content: space-between;
                      flex-flow: wrap;
                      margin-bottom: 20px;
                    ">
                    <router-link to="/" v-slot="{ navigate }">
                      <button-prime label="Trocar calculadora/UF" class="pvBtnPrime" icon="pi pi-arrow-left"
                        iconPos="left" @click="navigate" style="margin-bottom: 10px" />
                    </router-link>

                    <button-prime v-if="!mostraComodo && f.comodo.escolha != '0'" label="Exibir cômodos"
                      class="p-button-secondary" @click="
                        () => {
                          mostraComodo = true;
                        }
                      " style="margin-bottom: 10px" />
                    <button-prime v-if="mostraComodo && f.comodo.escolha != '0'" label="Ocultar cômodos"
                      class="p-button-secondary" @click="
                        () => {
                          mostraComodo = false;
                        }
                      " style="margin-bottom: 10px" />
                  </div>

                  <transition name="fade">
                    <div class="p-grid" v-show="mostraComodo">
                      <div class="p-col p-text-center">
                        <h1>SELECIONE UM CÔMODO:</h1>
                        <div class="pvComodos p-grid">
                          <div class="p-col-12 p-sm-6 p-md-4 p-lg-2 p-ripple" v-ripple
                            v-for="(comodo, i) in f.comodo.options" :key="comodo"
                            @click="pvAtivarComodo(i, comodo, itemIndex)">
                            <div class="pvComodo" :ref="'comodo' + itemIndex + i">
                              <img :alt="'Imagem ' + comodo.descricao" :src="
                                'https://pontoevirgulaprog.com/back-obrinha/assets/icones_comodos/icone_' +
                                comodo.descricao +
                                '.png'
                              " class="pvBtnIcon" />
                              <p>{{ comodo.descricao }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>

                  <div class="pvItemSelect" v-if="f.comodo.escolha.descricao != undefined">
                    <label v-if="!isEditing">{{
                      f.comodo.escolha.descricao
                    }}</label>
                    <button-prime v-if="!isEditing" icon="pi pi-pencil" class="p-button-outlined"
                      style="margin-left: 10px" @click="pvEditarDescricaoItem(f.comodo, itemIndex)" />
                    <button-prime v-if="isEditing" icon="pi pi-check" class="p-button-outlined p-button-success"
                      style="margin-left: 10px" @click="pvSalvarEdicaoItem(itemIndex)" />
                  </div>

                  <!-- se já tiver escolhido um comodo, exibe os inputs -->
                  <transition name="slide-fade" appear>
                    <div v-if="f.comodo.escolha != 0">
                      <hr />
                      <div class="pvMetragem">
                        <label style="display: block; margin-bottom: 10px">Qual é a metragem deste cômodo?</label>
                        <div class="pvInputNumber">
                          <input type="number" v-model="f.quantidade" @keyup="pvChangedMetragem()" />
                          <p style="
                              margin: 0;
                              border-left: 1px solid #ced4da;
                              padding-left: 10px;
                            ">
                            m²
                          </p>
                        </div>
                        <p style="
                            display: block;
                            margin-top: 30px;
                            margin-bottom: 0px;
                          ">
                          Preencha somente as partes onde acontecerá a reforma:
                        </p>
                      </div>

                      <accordion :active-index="openedTabs" :multiple="true"
                        @tab-open="pvGetServico(itemIndex, $event.index)">
                        <accordion-tab v-for="(optParte, optParteIndex) in f.parte" :key="'o' + optParteIndex" :header="
                          optParte.descricao +
                          '   - ' +
                          pvFormatToMoney(optParte.orcamento)
                        ">
                          <div>
                            <strong>Serviços</strong>
                            <div class="pvListServico pvPartList">
                              <div v-for="(servico, servicoIndex) in optParte
                              .servico.options" :key="servico">
                                <radio-button :id="
                                  'f' +
                                  itemIndex +
                                  'p' +
                                  optParteIndex +
                                  's' +
                                  servicoIndex
                                " name="category" :value="servico" v-model="optParte.servico.escolha" @change="
  pvGetMateriais(itemIndex, optParteIndex)
" />
                                <label class="pvRadioLabel" :for="
                                  'f' +
                                  itemIndex +
                                  'p' +
                                  optParteIndex +
                                  's' +
                                  servicoIndex
                                ">{{ servico.descricao }}</label>
                                <div class="more-info-icon">
                                  <span class="pi pi-info-circle"></span>
                                  <div class="info-window">
                                    <p>{{ servico.informacao || "Mais informações sobre o serviço" }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <transition name="fade" appear>
                            <div v-if="
                              optParte.servico.escolha != undefined &&
                              optParte.servico.escolha != '0'
                            ">
                              <hr />
                              <strong>Materiais</strong>
                              <div class="pvListMaterial pvPartList">
                                <div v-for="(material, materialIndex) in optParte
                                .material.options" :key="material">
                                  <radio-button :id="
                                    'f' +
                                    itemIndex +
                                    'p' +
                                    optParteIndex +
                                    'm' +
                                    materialIndex
                                  " name="category" :value="material" v-model="optParte.material.escolha" @change="
  pvGetPadrao(itemIndex, optParteIndex)
" />
                                  <label class="pvRadioLabel" :for="
                                    'f' +
                                    itemIndex +
                                    'p' +
                                    optParteIndex +
                                    'm' +
                                    materialIndex
                                  ">{{ material.descricao }}</label>
                                  <div class="more-info-icon">
                                    <span class="pi pi-info-circle"></span>
                                    <div class="info-window">
                                      <p>{{ material.informacao || "Mais informações sobre o material" }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </transition>
                          <transition name="fade" appear>
                            <div v-show="
                              optParte.padrao.options != undefined &&
                              optParte.material.escolha != '0'
                            ">
                              <hr />
                              <strong>Padrão</strong>
                              <div class="pvListPadrao pvPartList">
                                <div v-for="(padrao, padraoIndex) in optParte
                                .padrao.options" :key="padrao">
                                  <radio-button :id="
                                    'f' +
                                    itemIndex +
                                    'p' +
                                    optParteIndex +
                                    'p' +
                                    padraoIndex
                                  " name="category" :value="padrao" v-model="optParte.padrao.escolha" @change="
  pvGetOrcamento(itemIndex, optParteIndex)
" />
                                  <label class="pvRadioLabel" :for="
                                    'f' +
                                    itemIndex +
                                    'p' +
                                    optParteIndex +
                                    'p' +
                                    padraoIndex
                                  ">{{ padrao.descricao }}</label>
                                  <div class="more-info-icon">
                                    <span class="pi pi-info-circle"></span>
                                    <div class="info-window">
                                      <p>{{ padrao.informacao || "Mais informações sobre o padrão" }}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </transition>
                        </accordion-tab>
                      </accordion>
                    </div>
                  </transition>

                  <div style="margin-top: 20px">
                    <button-prime style="float: left" v-if="f.comodo.escolha != 0" label="Adicionar"
                      class="p-button-secondary" icon="pi pi-plus" iconPos="right" @click="pvAdicionarItem()" />
                    <button-prime style="float: right" label="Finalizar" class="pvBtnPrime" icon="pi pi-arrow-right"
                      iconPos="right" @click="pvFinalizar(itemIndex)" />
                  </div>
                </div>
              </div>
              <div style="
                  border-top: solid 1px #ccc;
                  margin-top: 30px;
                  color: rgb(150, 149, 149);
                  font-size: 14px;
                ">
                <p>
                  Desenvolvido por
                  <a style="color: #393939" href="#">Ponto&Vírgula</a> 2020.
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <!--div que representa o carrinho no celular para abrir a bottom bar -->
    <div class="pvCarrinhoResponsivo" @click="visibleCarrinho = !visibleCarrinho">
      <label>Total:
        <strong class="pvValor">{{
          pvFormatToMoney(orcamentoTotal)
        }}</strong></label>
      <i class="pi pi-angle-up" style="font-size: 20px"></i>
    </div>

    <!--bottom bar -->
    <Sidebar v-model:visible="visibleCarrinho" :baseZIndex="1000" position="bottom" class="p-sidebar-lg">
      <div class="pvHeaderCarrinhoResp">
        <h3>SEU CARRINHO: {{ pvFormatToMoney(orcamentoTotal) }}</h3>
      </div>
      <div v-for="(f, itemIndex) in form" :key="f">
        <div class="pvSidebarItem">
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <label v-if="f.comodo.escolha != 0" :style="f.item == opcao.item ? 'color: #277ba3' : ''">
              <i class="pi pi-angle-right" style="font-size: 20px" v-show="f.item == opcao.item"></i>
              {{ f.comodo.escolha.descricao }} <br />
              <small>{{ pvFormatToMoney(f.comodo.orcamento) }}</small>
            </label>
            <label v-else :style="f.item == opcao.item ? 'color: #277ba3' : ''">
              <i class="pi pi-angle-right" style="font-size: 20px" v-show="f.item == opcao.item"></i>
              Cômodo...?
            </label>
            <div>
              <button-prime icon="pi pi-pencil" class="pvBtnPrime p-button-outlined p-button-warning"
                style="margin-right: 10px" @click="pvClickedOnItem(itemIndex)" />
              <button-prime icon="pi pi-trash" class="pvBtnPrime p-button-outlined p-button-danger"
                @click="pvDeleteItem(itemIndex)" />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>

    <!-- modal para confirmar se deseja finalizar a simulação -->
    <custom-modal :visible="showConfirm" :disabled="isSavingInfo"
      text="Realmente deseja finalizar a simulação? Após esta ação você não poderá editar as informações."
      @fechar="(val) => { showConfirm = false; }" @continuar="() => pvIrParaFinalizar()">
    </custom-modal>
  </div>
</template>

<script>
import FormatMixin from "../mixins/FormatMixin";
import CustomModal from "./utils/CustomModal";

export default {
  name: "FormularioPage",
  mixins: [FormatMixin],
  components: {
    CustomModal,
  },
  data() {
    return {
      uf: "",
      opcao: { item: "Item 1", descricao: "item 1" },
      count: 1,
      form: sessionStorage.getItem("session-cart") ? JSON.parse(sessionStorage.getItem("session-cart")) : [],
      disableDropdown: true,
      cart: [],
      visible: false,
      visibleCarrinho: false,
      lastItemIndex: null,
      orcamentoTotal: 0,
      openedTabs: [],
      mostraComodo: true,
      erros: [],
      isEditing: false,
      showConfirm: false,
      isSavingInfo: false,
    };
  },
  methods: {
    pvEditarDescricaoItem: function (comodo, itemIndex) {
      this.isEditing = true;
      let textArea = document.createElement("textarea");
      textArea.style.fontSize = "40px";
      textArea.style.color = "#277ba3";
      textArea.style.fontFamily = "pvFontBold";
      textArea.style.width = "280px";
      textArea.style.resize = "none";
      textArea.innerHTML = comodo.escolha.descricao;
      let actualDiv = document.getElementsByClassName("pvItemSelect");
      actualDiv[itemIndex].insertAdjacentElement("afterbegin", textArea);
    },
    pvSalvarEdicaoItem: function (itemIndex) {
      this.isEditing = false;
      let newLabel = document.createElement("label");
      let actualDiv = document.getElementsByClassName("pvItemSelect");
      let textarea = actualDiv[itemIndex].getElementsByTagName("textarea")[0];
      let nomeComodo = textarea.value;

      newLabel.innerHTML = nomeComodo;
      this.form[itemIndex].comodo.escolha.descricao = nomeComodo;
      actualDiv[itemIndex].removeChild(textarea);
    },
    pvAtivarComodo: function (i, comodo, itemIndex) {
      if (this.isEditing) {
        this.pvSalvarEdicaoItem(itemIndex);
      }

      this.pvSelectButtonEffect(i, comodo, itemIndex);

      let dadosComodo = { id: comodo.id, descricao: comodo.descricao };
      this.form[itemIndex].comodo.orcamento = 0;
      this.pvTotalOrcamento();
      this.mostraComodo = false;
      this.form[itemIndex].comodo.escolha = dadosComodo;
      this.pvGetParte(this.form[itemIndex].comodo, itemIndex);
      this.updateSessionCart();
    },
    pvSelectButtonEffect: function (i, comodo, itemIndex) {
      let classes = document.getElementsByClassName("active");
      if (classes.length > 0) {
        classes[0].classList.remove("active");
      }

      this.$refs["comodo" + itemIndex + i][0]?.classList.toggle("active");
    },
    pvClickedOnItem: function (itemIndex) {
      this.opcao = this.form[itemIndex];
      if (this.opcao.comodo.escolha != "0") {
        this.openedTabs = [];
        this.pvSelectButtonEffect(
          parseInt(this.form[itemIndex].comodo.escolha.id - 1, 10),
          this.form[itemIndex].comodo.escolha,
          itemIndex
        );
      }
    },
    pvDeleteItem: function (itemIndex) {
      //TODO: Colocar modal pedindo confirmação e avisando que o item será perdido

      // se o usuário deletar o index que está ativo e tiver outro indice deixa outro ativo
      if (
        this.opcao.item == this.form[itemIndex].item &&
        this.form.length > 1
      ) {
        if (itemIndex == 0) {
          this.pvClickedOnItem(1);
        } else {
          this.pvClickedOnItem(0);
        }
      }

      // se não tiver mais nenhum item além do que vai deletar, cria um novo
      if (this.form.length == 1) {
        this.pvGetNewItem();
      }

      this.form.splice(itemIndex, 1);
      this.updateSessionCart();
      this.pvTotalOrcamento();
    },
    pvGetNewItem: function () {
      this.axios
        .get("/back-obrinha/comodos.php")
        .then((response) => {
          let dados = {
            label: "Comodo",
            tipo: "text",
            options: response.data,
            escolha: "0",
          };
          const lastItem = this.form[this.form.length - 1];

          let newItem = {
            item: "Item " + (lastItem ? parseInt(lastItem.item.split(" ")[1]) + 1 : "1"),
            descricao: "item " + (lastItem ? parseInt(lastItem.item.split(" ")[1]) + 1 : "1"),
            servico: "",
            quantidade: null,
            comodo: dados,
            parte: [],
          };
          this.form.push(newItem);
          this.opcao = newItem;
          this.disableDropdown = false;
          this.mostraComodo = true;

          // rolar a tela novamente para o topo quando um novo item for adicionado
          window.setTimeout(() => {
            let titleComodo =
              document.getElementsByTagName("h1")[this.form.length - 1];
            titleComodo.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }, 100);
        })
        .catch((error) => {
          this.erros.push({
            descricao: error.response.data.message,
            severity: "error",
          });
        });
    },
    pvGetParte: function (val, itemIndex) {
      this.axios
        .get("/back-obrinha/partes.php", {
          params: {
            id_comodo: val.escolha.id,
          },
        })
        .then((response) => {
          this.openedTabs = [];
          this.form[itemIndex].parte = [];
          response.data.forEach((d) => {
            let obj = {
              descricao: d.descricao,
              id: d.id,
              servico: {},
              material: {},
              padrao: {},
              orcamento: 0,
              orcamento_mao_obra: 0,
              orcamento_material: 0,
            };
            this.form[itemIndex].parte.push(obj);
          });
        })
        .catch((error) => {
          this.erros.push({
            descricao: error.response.data.message,
            severity: "error",
          });
        });
    },
    pvGetServico: function (itemIndex, parteIndex) {
      this.axios
        .get("/back-obrinha/servicos.php", {
          params: {
            id_comodo: this.form[itemIndex].comodo.escolha.id,
            id_parte: this.form[itemIndex].parte[parteIndex].id,
          },
        })
        .then((response) => {
          let escolha = "0";
          if (
            this.form[itemIndex].parte[parteIndex].servico.escolha !== undefined
          ) {
            for (var i = 0; i < response.data.length; i++) {
              if (
                response.data[i].id ==
                this.form[itemIndex].parte[parteIndex].servico.escolha.id
              ) {
                escolha =
                  this.form[itemIndex].parte[parteIndex].servico.escolha;
                this.pvGetNextValores("Serviço", itemIndex, parteIndex);
              }
            }
          }
          const servicos = response.data;

          this.form[itemIndex].parte[parteIndex].servico = {
            options: servicos,
            escolha: escolha,
          };
        })
        .catch((error) => {
          this.erros.push({
            descricao: error.response.data.message,
            severity: "error",
          });
        });
    },
    pvGetMateriais: function (itemIndex, parteIndex) {
      const materiais = this.form[itemIndex].parte[parteIndex].material;
      if (materiais?.options?.length > 0) {
        this.pvGetOrcamento(itemIndex, parteIndex);
        return;
      }

      this.axios
        .get("/back-obrinha/materiais.php", {
          params: {
            id_comodo: this.form[itemIndex].comodo.escolha.id,
            id_parte: this.form[itemIndex].parte[parteIndex].id,
            id_servico:
              this.form[itemIndex].parte[parteIndex].servico.escolha.id,
          },
        })
        .then((response) => {
          // verifica se o valor previamente selecionado, está entre os valores retornados pelo back-end
          let escolha = "0";
          if (
            this.form[itemIndex].parte[parteIndex].material.escolha !==
            undefined
          ) {
            for (var i = 0; i < response.data.length; i++) {
              if (
                response.data[i].id ==
                this.form[itemIndex].parte[parteIndex].material.escolha.id
              ) {
                escolha =
                  this.form[itemIndex].parte[parteIndex].material.escolha;
                // se o material selecionado estiver na response, busca os padrões novamente
                this.pvGetNextValores("Material", itemIndex, parteIndex);
              }
            }
          }

          const materiais = response.data;

          this.form[itemIndex].parte[parteIndex].material = {
            options: materiais,
            escolha: escolha,
          };

          if (materiais.length == 1) {
            this.form[itemIndex].parte[parteIndex].material.escolha =
              materiais[0];
            this.pvGetPadrao(itemIndex, parteIndex);
          }
        })
        .catch((error) => {
          this.erros.push({
            descricao: error.response.data.message,
            severity: "error",
          });
        });

      this.pvGetOrcamento(itemIndex, parteIndex);
    },
    pvGetPadrao: function (itemIndex, parteIndex) {
      const padroes = this.form[itemIndex].parte[parteIndex].padrao;
      if (padroes?.options?.length > 0) {
        this.pvGetOrcamento(itemIndex, parteIndex);
        return;
      }

      this.axios
        .get("/back-obrinha/padrao.php", {
          params: {
            id_comodo: this.form[itemIndex].comodo.escolha.id,
            id_parte: this.form[itemIndex].parte[parteIndex].id,
            id_servico:
              this.form[itemIndex].parte[parteIndex].servico.escolha.id,
            id_material:
              this.form[itemIndex].parte[parteIndex].material.escolha.id,
          },
        })
        .then((response) => {
          let escolha = "0";
          if (
            this.form[itemIndex].parte[parteIndex].padrao.escolha !== undefined
          ) {
            for (var i = 0; i < response.data.length; i++) {
              if (
                response.data[i].id ==
                this.form[itemIndex].parte[parteIndex].padrao.escolha.id
              ) {
                escolha = this.form[itemIndex].parte[parteIndex].padrao.escolha;
              }
            }
          }

          const padroes = response.data;

          this.form[itemIndex].parte[parteIndex].padrao = {
            options: padroes,
            escolha: escolha,
          };

          if (padroes.length == 1) {
            this.form[itemIndex].parte[parteIndex].padrao.escolha =
              padroes[0];
            this.pvGetOrcamento(itemIndex, parteIndex);
          }
        })
        .catch((error) => {
          this.erros.push({
            descricao: error.response.data.message,
            severity: "error",
          });
        });
    },
    pvGetNextValores(type, itemIndex, parteIndex) {
      switch (type) {
        case "Serviço":
          this.pvGetMateriais(itemIndex, parteIndex);
          break;
        case "Material":
          this.pvGetPadrao(itemIndex, parteIndex);
          break;
        case "Padrão":
          this.pvGetOrcamento(itemIndex, parteIndex);
          break;
        default:
          break;
      }
    },
    pvFindCurrentForm: function () {
      for (let i = 0; i < this.form.length; i++) {
        if (this.form[i].item == this.opcao.item) return i;
      }
      return null;
    },
    pvIndexOfIncompleteForm: function () {
      for (let itemIndex = 0; itemIndex < this.form.length; itemIndex++) {
        if (!this.pvFormValidator(itemIndex)) return itemIndex;
      }
      return null;
    },
    pvFormValidator: function (formIndex = null) {
      formIndex = formIndex == null ? this.pvFindCurrentForm() : formIndex;
      let isValid = false;

      // permitir finalizar quando ainda não tiver selecionado nenhum comodo
      if (this.form[formIndex].comodo.escolha == 0 && this.form.length > 1)
        return true;

      if (
        this.form[formIndex].quantidade == 0 ||
        this.form[formIndex].quantidade == null
      )
        return false;

      for (let i = 0; i < this.form[formIndex].parte.length; i++) {
        if (this.pvParteValidator(formIndex, i)) isValid = true;
      }
      return isValid;
    },
    pvParteValidator: function (formIndex, parteIndex) {
      let isValid = false;
      if (
        !(this.form[formIndex].parte[parteIndex].servico.escolha == undefined)
      ) {
        if (
          !(
            this.form[formIndex].parte[parteIndex].material.escolha == undefined
          )
        ) {
          if (
            !(
              this.form[formIndex].parte[parteIndex].padrao.escolha == undefined
            )
          ) {
            if (
              !(this.form[formIndex].parte[parteIndex].padrao.escolha == "0")
            ) {
              isValid = true;
            }
          }
        }
      }
      return isValid;
    },
    pvAdicionarItem() {
      if (this.pvFormValidator()) {
        let i = this.pvIndexOfIncompleteForm();
        if (i != null) {
          this.pvClickedOnItem(i);
        } else {
          this.pvGetNewItem();
        }
      } else {
        this.erros.push({
          descricao: "Preencha pelo menos uma parte completamente!",
          log: "line 524, form validation failed",
          severity: "error",
        });
      }
    },
    pvDecideOrcamentoIndex: function (itemIndex) {
      if (itemIndex != undefined) {
        this.lastItemIndex = itemIndex;
        return itemIndex;
      } else {
        itemIndex = this.lastItemIndex;
        return itemIndex;
      }
    },
    pvChangedMetragem: function (value = null) {
      let formIndex = this.pvFindCurrentForm();
      if (value != null) this.form[formIndex].quantidade = value;
      for (let i = 0; i < this.form[formIndex].parte.length; i++) {
        if (this.pvParteValidator(formIndex, i)) {
          this.pvGetOrcamento(formIndex, i);
        }
      }
    },
    pvGetOrcamento: function (itemIndex, parteIndex) {
      if (
        this.form[itemIndex].quantidade == 0 ||
        this.form[itemIndex].quantidade == null
      ) {
        this.erros.push({
          descricao: "Insira a metragem do cômodo para ver o orçamento!",
          severity: "warn",
        });
      }
      this.uf = localStorage.getItem("uf");
      this.axios
        .get("/back-obrinha/orcamento.php", {
          params: {
            id_comodo: this.form[itemIndex].comodo.escolha.id,
            id_parte: this.form[itemIndex].parte[parteIndex].id,
            id_material:
              this.form[itemIndex].parte[parteIndex].material.escolha.id,
            id_servico:
              this.form[itemIndex].parte[parteIndex].servico.escolha.id,
            id_padrao: this.form[itemIndex].parte[parteIndex].padrao.escolha.id,
            id_uf: this.uf,
            metragem: this.form[itemIndex].quantidade,
          },
        })
        .then((response) => {
          let orcamento =
            response.data.length == 0 || response.data.total == null
              ? "0.00"
              : response.data.total;
          this.form[itemIndex].parte[parteIndex].orcamento =
            parseFloat(orcamento);
          this.form[itemIndex].parte[parteIndex].orcamento_mao_obra =
            parseFloat(response.data.mao_obra);
          this.form[itemIndex].parte[parteIndex].orcamento_material =
            parseFloat(response.data.material);
          this.pvTotalComodo(itemIndex);
          this.pvTotalOrcamento();
          this.updateSessionCart();
        })
        .catch((error) => {
          this.erros.push({
            descricao: error.response.data.message,
            severity: "error",
          });
        });
    },
    pvTotalComodo: function (itemIndex) {
      this.form[itemIndex].comodo.orcamento = 0;
      this.form[itemIndex].parte.forEach((p) => {
        this.form[itemIndex].comodo.orcamento += p.orcamento;
      });
    },
    pvTotalOrcamento: function () {
      this.orcamentoTotal = 0;
      this.form.forEach((f) => {
        if (f.comodo.orcamento != undefined && f.comodo.orcamento != null)
          this.orcamentoTotal += f.comodo.orcamento;
      });
    },
    pvFormatNumber: function (orcamento) {
      return orcamento.slice(orcamento.indexOf("$") + 1);
    },
    pvFinalizar: function () {
      if (this.pvFormValidator()) {
        let i = this.pvIndexOfIncompleteForm();
        if (i != null) {
          this.pvClickedOnItem(i);
          this.erros.push({
            descricao: "Esse cômodo está incompleto, complete-o ou exclua-o!",
            severity: "error",
          });
        } else {
          // exibe modal de confirmação
          this.showConfirm = true;
        }
      } else {
        this.erros.push({
          descricao: "Preencha pelo menos uma parte completamente!",
          log: "line 486 form validation failed",
          severity: "error",
        });
      }
    },
    pvIrParaFinalizar: function () {
      this.isSavingInfo = true

      localStorage.setItem("dados", JSON.stringify(this.form));

      const params = new URLSearchParams();
      params.append("idOrcamento", sessionStorage.getItem("id_orcamento") ?? '');
      params.append("email", sessionStorage.getItem('email') ?? '');
      params.append("dados", JSON.stringify(this.form));

      this.axios.post("/back-obrinha/simulacao_usuario.php", params)
        .then(() => {
          this.$router.push("/finalizar");
          this.isSavingInfo = false;
        }).catch(() => {
          this.isSavingInfo = false;
          this.erros.push({ descricao: "Ocorreu um erro ao salvar simulacao", log: "Simulation creation failed" });
        })
    },
    updateSessionCart: function () {
      console.log(this.form);
      const cart = this.form.filter((value) => !!value.quantidade);
      sessionStorage.setItem("session-cart", JSON.stringify(cart));
    },
  },
  beforeCreate() {
    // se não tiver um uf selecionado no localStorage, redireciona para a tela inicial
    if (localStorage.getItem("uf") === null) {
      this.$router.push("/");
    }

    this.axios
      .get("/back-obrinha/comodos.php")
      .then((response) => {
        this.comodos = response.data;

        let comodo = {
          label: "Comodo",
          tipo: "text",
          options: response.data,
          escolha: "0",
          orcamento: 0,
        };

        let parte = [];

        let newItem = {
          item: "Item 1",
          descricao: "item " + (this.form.length + 1),
          servico: "",
          quantidade: null,
          comodo: comodo,
          parte: parte,
        };

        if (this.form.length > 0) {
          this.mostraComodo = false;
          this.pvGetNewItem();
          return;
        }
        this.form[0] = newItem;
      })
      .catch((error) => {
        this.erros.push({
          descricao: error.response.data.message,
          severity: "error",
        });
      });
  },
};
</script>

<style scoped>
.p-ripple.purple .p-ink {
  background: rgba(256, 39, 176, 0.3);
}

@font-face {
  font-family: "pvFont";
  src: url(../assets/font/Montserrat-Regular.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "pvFontBold";
  src: url(../assets/font/Montserrat-Bold.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}

.pvInputNumber {
  border-radius: 3px;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #ced4da;
  width: 225px;
  display: flex;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

div.pvInputNumber:hover {
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #277ba3;
}

.pvInputNumber input {
  border: 0px solid white;
}

.pvInputNumber input:focus {
  outline-width: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.pvCarrinhoLateral {
  padding: 20px;
  height: 100vh;
  overflow: auto;
}

.pvMainForm {
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
}

.pvMetragem {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pvRadioLabel {
  cursor: pointer;
  margin: 0px 4px;
}

.pvComodos {
  justify-content: center;
}

.pvComodo {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  box-shadow: 0 8px 18px -8px #226c8f;
  font-family: pvFont;
  border-radius: 4px;
  border: solid 1px #277ba3;
  margin-bottom: 10px;
  transition: all 0.4s ease-out;
}

.pvComodo:hover {
  transform: translateY(-4px);
  transition: all 0.4s ease-out;
  background-color: rgba(39, 123, 163, 0.3);
}

.pvComodo.active {
  transform: translateY(8px);
  transform: scale(0.95);
  box-shadow: none;
  background-color: #277ba3;
  transition: all 0.4s ease-out;
  color: white;
}

.pvItemSelect {
  font-size: 40px;
  font-family: pvFontBold;
  color: #277ba3;
  display: flex;
  align-items: flex-end;
}

.pvSidebarItem {
  padding: 20px 5px;
  width: 100%;
  font-size: 20px;
  color: #393939;
  border-top: solid 1px #ccc;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.pvBtnIcon {
  height: 50px;
  display: block;
  align-self: center;
}

h1 {
  font-size: 25px;
  font-style: bold;
}

.pvCarrinhoResponsivo {
  padding: 15px;
  position: fixed;
  bottom: 0;
  color: white;
  background-color: #277ba3;
  width: 100%;
  left: 0;
  display: none;
  z-index: 999;
}

.pvHeaderCarrinhoResp {
  color: white;
  background-color: #277ba3;
  width: 100%;
  padding: 1px 15px;
}

.pvListServico,
.pvListMaterial,
.pvListPadrao {
  margin: 10px 0;
  display: flex;
}

.pvPartList {
  display: flex;
  column-gap: 16px;
  row-gap: 12px;
  flex-wrap: wrap;
}

.pvPartList>div {
  display: flex;
  position: relative;
}

.more-info-icon {
  height: 12px;
  display: flex;
  align-self: start;
  cursor: pointer;
}

.more-info-icon>span {
  font-size: 12px;
  color: #277ba3;
}

.info-window {
  display: none;
}

.info-window>p {
  margin: 0;
}

.more-info-icon:hover>.info-window {
  display: block;
  position: absolute;
  bottom: calc(100% + 2px);
  right: 0;
  background: #fff;
  border: 1px solid #277ba3;
  border-radius: 4px;
  padding: 12px;
  z-index: 9999;
  box-shadow: 2px 2px 6px rgba(72, 72, 72, 0.522);
  width: max-content;
  max-width: fit-content;
}

.footerCarrinho {
  color: white;
  background-color: #277ba3;
  padding: 20px 5px;
}

.pvBtnPrime {
  background-color: #277ba3;
}

.pvBtnPrime:hover {
  background-color: #2b8bb8;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .pvRadioLabel {
    flex-grow: 1;
  }

  .pvDivLateral .pvCarrinhoLateral {
    height: auto;
  }

  .pvCarrinhoResponsivo {
    display: flex;
    justify-content: space-between;
  }

  .pvCarrinho {
    display: none;
  }

  .pvMainForm {
    height: auto;
  }
}

@media screen and (max-width: 567px) {
  .pvMainForm {
    padding-bottom: 70px;
  }

  .pvComodo {
    flex-direction: row !important;
  }

  .pvComodo p {
    margin: 0;
  }

  .pvBtnIcon {
    height: 20px;
    display: inline !important;
  }
}
</style>
