<template>
  <div style="overflow: hidden; font-family: pvFont">
    <transition name="slide-fade" appear>
      <div class="p-grid">
        <div class="p-md-4 p-sm-12 pvDivLateral">
          <div class="pvCarrinhoLateral">
            <div class="p-text-center">
              <router-link to="/" v-slot="{ navigate }">
                <img src="../assets/logo.png" style="max-width: 100%; margin: auto" @click="navigate" />
              </router-link>
              <p style="
                  color: rgb(187 187 187);
                  font-style: italic;
                  font-size: 14px;
                ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Praesent convallis ligula vitae augue aliquam, in porta purus
                commodo.
              </p>
            </div>
          </div>
        </div>

        <div class="p-md-8 p-sm-12">
          <div class="pvMainForm">
            <div style="display: flex; flex-direction: column">
              <Message v-for="err in erros" severity="error" :key="err.descricao" :life="4000" :sticky="false">
                {{ err.descricao }}</Message>
              <Message v-for="message in messages" severity="success" :key="message.descricao" :life="4000"
                :sticky="false">{{ message.descricao }}</Message>

              <div class="header">
                <h2>Resumo do Orçamento</h2>
              </div>

              <label>O orçamento estimado para a sua reforma é de R$ {{ pvFormatToMoney(valorTotal) }}, conforme o
                detalhamento a seguir: </label>

              <div class="comodo" v-for="dado in dados" :key="dado" v-show="dado.comodo.escolha != 0">
                <div class="nomeComodo">
                  <label>{{ dado.comodo.escolha.descricao }} - {{ dado.quantidade }} m²</label>
                </div>

                <div class="table-responsive">
                  <table class="tabela">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Valor Material</th>
                        <th>Valor Mão de obra</th>
                        <th>Valor Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="parte in dado.parte" :key="parte" v-show="parte.servico.escolha != undefined">
                        <td>{{ parte.descricao }}</td>
                        <td>{{ pvFormatToMoney(parte.orcamento_material) }}</td>
                        <td>{{ pvFormatToMoney(parte.orcamento_mao_obra) }}</td>
                        <td>{{ pvFormatToMoney(parte.orcamento) }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3">Total:</td>
                        <td><strong> {{ pvGetTotalComodo(dado) }}</strong></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div style="display: flex; justify-content: flex-end; margin-top: 20px">
                <p v-if="enviando" style="margin-right: 20px">Enviando e-mail...</p>
                <button-prime :disabled="enviando ? 'disabled' : false" class="pvBtnPrime" @click="enviarEmail">
                  Enviar orçamento para {{ this.email }}
                </button-prime>
              </div>
            </div>

            <div style="
                border-top: solid 1px #ccc;
                margin-top: 30px;
                color: rgb(150, 149, 149);
                font-size: 14px;
              ">
              <p>
                Desenvolvido por
                <a style="color: #393939" href="#">Ponto&Vírgula</a> 2020.
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import FormatMixin from '../mixins/FormatMixin';

export default {
  name: "FinalizarPage",
  mixins: [FormatMixin],
  data() {
    return {
      email: null,
      erros: [],
      messages: [],
      enviando: false,
      enviar: false,
      dados: [],
      valorTotal: 0,
      completa: false,
      resumo: true
    };
  },
  methods: {
    enviarEmail() {
      if (this.email != null) {
        this.enviando = true;

        this.axios.get('/back-obrinha/makepdf.php', {
          params: {
            email: this.email,
            completa: this.completa,
            resumo: this.resumo,
            dados: JSON.stringify(this.dados)
          }
        })
          .then(() => {
            sessionStorage.removeItem("id_orcamento");
            localStorage.clear();
            this.messages.push({ descricao: "E-mail enviado com sucesso para " + this.email + ".", log: "Email sended" });
            this.enviando = false;
            window.setTimeout(() => {
              this.$router.push('/');
            }, 4000);
          }).catch(() => {
            this.enviando = false;
            this.erros.push({ descricao: "Ocorreu um erro ao enviar o seu e-mail! Tente novamente.", log: "Email validation failed" });
          });
      } else {
        this.erros.push({ descricao: "Informe um e-mail para continuar!", log: "Email validation failed" });
      }
    },
    pvGetTotalComodo(dado) {
      let total = 0;
      for (let j = 0; j < dado.parte.length; j++) {
        total += dado.parte[j].orcamento;
      }

      return this.pvFormatToMoney(total);
    }
  },
  created() {
    if (localStorage.getItem('dados') == null) {
      this.$router.push('/');
    } else {
      this.dados = JSON.parse(localStorage.getItem('dados'));

      this.email = sessionStorage.getItem('email');

      for (let i = 0; i < this.dados.length; i++) {
        for (let j = 0; j < this.dados[i].parte.length; j++) {
          this.valorTotal += this.dados[i].parte[j].orcamento;
        }
      }
    }
  }
};
</script>

<style scoped>
.pvMainForm {
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);

  padding: 20px;
  height: 100vh;

  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pvBtnPrime {
  background-color: #277ba3;
}

.pvBtnPrime:hover {
  background-color: #2b8bb8;
}

input {
  border-radius: 3px;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 5px;
  border: 1px solid #ced4da;
}

.center {
  text-align: center;
}

.right {
  width: 100%;
  text-align: right;
}

.nomeComodo {
  font-size: 20px;
  color: #37A6DE;
  font-weight: bold;
  margin-top: 20px;
}

.tabela {
  width: 100%;
}

h2 {
  text-align: center;
}

thead tr,
tfoot tr {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  background-color: #f8f8f8;
}

td,
th {
  padding: 10px;
  border-bottom: solid 1px #ccc;
}

th {
  border-top: solid 1px #ccc;
  text-align: left;
}

.lblValor {
  text-align: right;
}

table {
  border-spacing: 0;
  border-collapse: separate;
}

.header {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.comodo {
  margin-bottom: 20px;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.pvCarrinhoLateral {
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .pvDivLateral .pvCarrinhoLateral {
    height: auto;
  }

  .pvMainForm {
    height: auto;
  }

  .table-responsive {
    max-width: 90vw;
  }
}
</style>
