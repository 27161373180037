var FormatMixin = {
    methods: {
        pvFormatToMoney: function(number){
            if (number === null) {
                return '0,00';
            }

            number = number.toString();
            if(number == null || number == "0" || number == undefined)return "R$ 0,00"
            let i = number.indexOf(".",0);
            if (i >= 0) {
                number = i == -1 ? number : number.replace(".",",").substring(0, i+3);
            } else {
                number += ',00';
            }
            
            return "R$ " + number;
          }
    }
};

export default FormatMixin;