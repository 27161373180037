<template>
  <div>
    <div class="backdrop" v-if="show">
      <div class="modal">
        <div class="header">
          <div class="modal-title">
            <h3>Aviso!</h3>
          </div>
          <div class="btn-close">
            <button-prime :disabled="disabled" icon="pi pi-times" class="p-button-outlined p-button-sm" iconPos="right"
              @click="fechar" />
          </div>
        </div>

        <div class="modal-body">
          <p>{{ text }}</p>
        </div>

        <div class="modal-footer">
          <button-prime :disabled="disabled" label="Fechar" class="p-button-secondary" @click="fechar" />
          <button-prime :disabled="disabled" label="Continuar" @click="ok" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomModal',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      show: true,
    }
  },
  methods: {
    fechar() {
      this.show = false;
      this.$emit('fechar');
    },
    ok() {
      this.show = false;
      this.$emit('continuar');
    }
  },
  watch: {
    visible: function (val) {
      this.show = val;
    }
  },
  created() {
    this.show = this.visible;
  }
}
</script>

<style>
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}

.modal {
  max-width: 600px;
  min-width: 300px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #ccc;
  align-items: center;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
</style>
