<template>
    <div class="pvSelect">
        <input id="search" autocapitalize="off" autocomplete="off" spellcheck="false" autocorrect="off" type="text" placeholder="Buscar.." v-model="busca" @input="buscar()" @focus="exibe = true">
        
        <div>
            <i class="pi pi-times" style="margin-right: 10px" v-show="exibe" @click="exibe = false"></i>
            <i class="pi pi-search border"></i>
        </div>
    </div>
    <div class="dropdown-content" v-show="exibe">
        <a v-for="option in filteredOptions" :key="option.id" @click="selecionar(option)">{{ option.descricao }}</a>
        <a v-if="filteredOptions.length == 0" style="padding:10px">Nenhum estado encontrado</a>
    </div>
</template>

<script>
export default {
    name: "FilteredSelect",
    emits: ["selected"],
    props: {
        options: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data () {
        return {
            filteredOptions: [],
            exibe: false,
            busca: null
        }
    },
    methods: {
        buscar() {
            document.getElementById('search').blur();
            document.getElementById('search').focus();

            
            if (this.busca == '') {
                return this.filteredOptions = this.options;
            }
            this.filteredOptions = this.options.filter(option => {
                return option.descricao.toUpperCase().includes(this.busca.toUpperCase())
            });
            if(this.filteredOptions.length == 0){
                console.log('n tem nenhum lek');
                this.$emit('selected', null);
            }
        },
        selecionar(option) {
            this.busca = option.descricao;
            this.$emit('selected', option);
            this.exibe = false;
        }
    },
    created() {
        this.filteredOptions = this.options;
    },
    watch: {
        options: function() {
            this.filteredOptions = this.options;
        }
    }
}
</script>

<style scoped>
.dropdown-content {
    background-color: #f8f8f8;
    min-width: 230px;
    max-height: 300px;
    overflow: auto;
    border: 1px solid #ddd;
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 7px 16px;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.pvSelect {
    border-radius: 3px;
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 5px;
    border: 1px solid #ced4da;
    display: flex;
    justify-content: space-between;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

div.pvSelect:hover{
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #277ba3;
}

.pvSelect input{
  border: 0px solid white;
  width: 85%;
  font-size: 14px;
  font-family: pvFont;
}

.pvSelect input:focus {
    outline-width: 0;
}

.pvSelect i.border {
    border-left: solid 1px #ced4da;
    padding: 0 10px;
}

i {
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .pvSelect input{
        border: 0px solid white;
        width: 80%;
    }
}

@media screen and (max-width: 380px) {
    .pvSelect input{
        border: 0px solid white;
        width: 75%;
    }
}

@media screen and (max-width: 300px) {
    .pvSelect input{
        border: 0px solid white;
        width: 50%;
    }
}
</style>