import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createWebHistory, createRouter } from "vue-router";

//PRIMEVUE
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Sidebar from 'primevue/sidebar';
import Ripple from 'primevue/ripple';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';


// Componentes
import HomePage from './components/HomePage';
import FormularioPage from './components/FormularioPage';
import FinalizarPage from './components/FinalizarPage';

import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// const URL_BASE = 'https://pontoevirgulaapi.000webhostapp.com';
const URL_BASE = 'https://orcamentoobra.000webhostapp.com';

const app = createApp(App);

//PRIMEVUE components
app.component('buttonPrime', Button);
app.component('dropdown', Dropdown);
app.component('inputNumber', InputNumber);
app.component('accordion', Accordion);
app.component('accordionTab', AccordionTab);
app.component('radioButton', RadioButton);
app.component('Sidebar', Sidebar);
app.component('Message',Message);
app.directive('ripple', Ripple);

app.use(VueAxios, axios);
app.config.globalProperties.$primevue = {ripple: true};

app.axios.defaults.baseURL = URL_BASE;

const routes = [
    { path: '/', component: HomePage },
    { path: '/simular', component: FormularioPage },
    { path: '/finalizar', component: FinalizarPage }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

app.use(router).mount('#app')
